// extracted by mini-css-extract-plugin
export var integrationLogo = "Integration-module--integrationLogo--2F8Nt";
export var integrationLogoLine = "Integration-module--integrationLogoLine--1pHLA";
export var integrationLogoSet = "Integration-module--integrationLogoSet--12Sqe";
export var integrationPage = "Integration-module--integrationPage--1gypE";
export var pairings = "Integration-module--pairings--2s8Ij";
export var smallTitle = "Integration-module--smallTitle--ret5d";
export var splashCta = "Integration-module--splashCta--hSrl2";
export var splashImage = "Integration-module--splashImage--eU98E";
export var splashImageOverlay = "Integration-module--splashImageOverlay--3Y6o3";
export var splashImageWrapper = "Integration-module--splashImageWrapper--2L81y";
export var subtitle = "Integration-module--subtitle--1aOd2";
export var title = "Integration-module--title--koBJ8";