import React from "react"

import Layout from "@src/components/Layout"
import OG from "@src/icons/og/OG_Landing.jpeg"
import SplashIcon from "@src/icons/v3/integration-splash-2.png"
import TaskManagement from "@src/icons/v3/task-management.png"
import PipelineManagement from "@src/icons/v3/pipeline-management.png"
import ProjectManagement from "@src/icons/v3/project-management.png"

import Row from "../components/Row"
import Column from "../components/Column"
import Splash from "../Features/components/Splash"
import DotBG from "../components/DotBG"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import getAppUrl from "@src/utils/getAppUrl"
import CalloutWithDemoForm from "../../../components/CalloutWithDemoForm"
import Button from "../../../components/Button"

import * as styles from "./Integration.module.scss"

const IndexPage = ({ location, pageContext }) => {
  const [splashImage, setSplashImage] = React.useState()
  const [cardsImage, setCardsImage] = React.useState()
  const breakpoints = useBreakpoint()
  const appUrl = getAppUrl.hook()
  const baseUrl =
    process.env.NODE_ENV === "staging"
      ? "https://staging.canonic.dev"
      : "https://canonic.dev"

  const {
    title,
    subtitle,
    illustration,
    integration,
    logoUrl: integrationLogo,
    sections: propSections,
    og,
  } = pageContext?.content || {}
  const sections = propSections.map(s => ({
    ...s,
    items: s.items.map(item => ({
      ...item,
      subtitle: item.description,
      image: item.icon,
      icon: () => (
        <i
          class={`fa ${item.icon?.startsWith("fa") ? item?.icon : "fa-bolt"}`}
        />
      ),
    })),
  }))
  React.useEffect(() => {
    sections[0].items.map(
      async ({ title, image }) =>
        await import(`../../../icons/integration/${image}`).then(data =>
          setCardsImage(prevValue => ({ ...prevValue, [title]: data.default }))
        )
    )
    const splashImage = async () =>
      await import(`../../../icons/integration/${illustration}`).then(data =>
        setSplashImage(data.default)
      )
    splashImage()
  }, [pageContext])

  React.useEffect(() => {
    const link = document.createElement("link")
    link.id = "fontawesome-link"
    link.rel = "stylesheet"
    link.href =
      "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css"
    document.head.appendChild(link)
  }, [])

  return (
    <Layout
      title={title}
      description={subtitle}
      url={`https://canonic.dev${pageContext?.slug}`}
      location={location}
      ogImage={`${baseUrl}/og/${og}`}
      className={styles.integrationPage}
    >
      <Splash
        title={title}
        subtitle={subtitle}
        reverseOnMobile={true}
        illustration={
          <div className={styles.splashImageWrapper}>
            {!integration ? (
              <>
                <img className={styles.splashImage} src={SplashIcon} />
                <img
                  className={styles.splashImageOverlay}
                  src={integrationLogo}
                />
              </>
            ) : (
              <img className={styles.splashImageWrapper} src={splashImage} />
            )}
          </div>
        }
        ctas={[
          <a href={appUrl} target="_blank" rel="noopener noreferrer">
            <Button isPrimary>Try for free</Button>
          </a>,
          <a href={"#get-a-demo"}>
            <Button borderless className={styles.splashCta}>
              Get a Demo
            </Button>
          </a>,
        ]}
      />
      <Row className={styles.row}>
        <section>
          <h2 className={styles.title}>{sections[0].title}</h2>
          <Column.Wrap numPerRow={3}>
            {sections[0].items.map(({ title, description, icon }, i) => (
              <Column
                title={title}
                icon={() => (
                  <img
                    className="image-preview"
                    src={
                      !cardsImage
                        ? i === 0
                          ? TaskManagement
                          : i === 1
                          ? PipelineManagement
                          : ProjectManagement
                        : cardsImage?.[title]
                    }
                  />
                )}
                description={description}
              />
            ))}
          </Column.Wrap>
        </section>
      </Row>

      {sections.slice(1, 2).map(({ title, items }, i) => {
        return (
          <Row className={styles.pairings}>
            <h2 className={styles.title}>{title}</h2>
            <Column.Wrap numPerRow={2}>
              {items.map(({ title, description, logoUrl, readmore }) => (
                <Column
                  horizontal
                  title={title}
                  icon={() => <Icon linkLogo={integrationLogo} url={logoUrl} />}
                  description={description}
                  readmore={readmore}
                />
              ))}
            </Column.Wrap>
          </Row>
        )
      })}
      {sections.slice(2, 3).map(({ title, items }, i) => {
        return (
          <Row>
            <h2 className={styles.title}>{title}</h2>
            <Column.Wrap numPerRow={3}>
              {items.map(({ title, description, icon }) => (
                <Column title={title} icon={icon} description={description} />
              ))}
            </Column.Wrap>
          </Row>
        )
      })}
      <CalloutWithDemoForm originPage={integration} id={"get-a-demo"} />
    </Layout>
  )
}

function Icon({ url, linkLogo }) {
  return (
    <div className={styles.integrationLogoSet}>
      <img className={styles.integrationLogo} src={linkLogo} />
      <div className={styles.integrationLogoLine} />
      <img className={styles.integrationLogo} src={url} />
    </div>
  )
}

function useLogo(title) {
  const [url, setUrl] = React.useState()
  React.useEffect(() => {
    fetch(`https://company.clearbit.com/v1/domains/find?name=${title}`, {
      headers: { Authorization: "Bearer sk_2170bc5601dc2e7d31fa8ab6bd5c969a" },
    })
      .then(res => res.json())
      .then(d => setUrl(d.logo))
  }, [])

  return url
}

export default IndexPage
